// Imports
import React, { useRef, useEffect } from 'react';
import IconCanvas from './../../assets/img/icon_elevty.png'

const CanvasComponent = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const image = new Image();
    image.src = IconCanvas;

    image.onload = () => {
      // Dibuja la imagen en el canvas una vez que se haya cargado
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
    };
  }, []);

  return <canvas className='translate-y-10' ref={canvasRef} width={70} height={70} />;
};

export default CanvasComponent;

