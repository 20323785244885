//IMPORTS
import {connect} from 'react-redux'
import logo from './../../assets/img/logo_titular_elevty.png' 

//CONST
//COMPONENT
function Footer(){
    return(
      <footer className="bg-neutroColor flex flex-col justify-center" aria-labelledby="footer-heading">
        {/* Grid */}
        <div className='px-10 pt-10 xs:px-10 xs:pt-10 sm:px-24 sm:pt-14 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-4 sm:gap-x-10'>
          {/* Logo */}
          <div className=''>
            <img alt='logo_elevty' src={logo} className=' w-[135px] h-[90px]' />
          </div>
          {/* Adress */}
          <div className='flex flex-col'>
            <p className='text-sm font-overpass font-normal text-zinc-600'>
              Dirección
            </p>
            <p className='pt-2 text-sm font-overpass font-light text-zinc-600'>
              Conchalí, Santiago de Chile 
            </p>
          </div>
          {/* Services */}
          <div className='flex flex-col'>
            <p className='text-sm font-overpass font-normal text-zinc-600'>
              Servicios 
            </p>
            <ul className='pt-2 text-sm font-overpass font-light text-zinc-600'>
              <li>Seguimiento KPI</li>
              <li>Optimización SEO</li>
              <li>Adquisicion de Clientes</li>
              <li>Appoitment Setters</li>
            </ul>
          </div>
          {/* Social Media */}
          <div className='flex flex-col'>
            <p className='text-sm font-overpass font-normal text-zinc-600'>
              Redes Sociales 
            </p>
            <div className='flex justify-between px-10 -translate-x-11'>
              <a href="" target="_blank" rel="noopener noreferrer">
                <svg className='pt-1 fill-zinc-800 hover:fill-baseColor transition duration-300 ease-in-out' xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 50 50">
                    <path d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z"></path>
                </svg>
              </a>
              <a href="https://www.linkedin.com/in/axelreyesr" target="_blank" rel="noopener noreferrer">
                <svg className='pt-1 fill-zinc-800 hover:fill-baseColor transition duration-300 ease-in-out' xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 50 50">
                  <path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M17,20v19h-6V20H17z M11,14.47c0-1.4,1.2-2.47,3-2.47s2.93,1.07,3,2.47c0,1.4-1.12,2.53-3,2.53C12.2,17,11,15.87,11,14.47z M39,39h-6c0,0,0-9.26,0-10 c0-2-1-4-3.5-4.04h-0.08C27,24.96,26,27.02,26,29c0,0.91,0,10,0,10h-6V20h6v2.56c0,0,1.93-2.56,5.81-2.56 c3.97,0,7.19,2.73,7.19,8.26V39z"></path>
                </svg>
               </a>
               <a href="" target="_blank" rel="noopener noreferrer">
                 <svg className='pt-1 fill-zinc-800 hover:fill-baseColor transition duration-300 ease-in-out' xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="35" height="35" viewBox="0 0 32 32">
                 <path d="M 11.46875 5 C 7.917969 5 5 7.914063 5 11.46875 L 5 20.53125 C 5 24.082031 7.914063 27 11.46875 27 L 20.53125 27 C 24.082031 27 27 24.085938 27 20.53125 L 27 11.46875 C 27 7.917969 24.085938 5 20.53125 5 Z M 11.46875 7 L 20.53125 7 C 23.003906 7 25 8.996094 25 11.46875 L 25 20.53125 C 25 23.003906 23.003906 25 20.53125 25 L 11.46875 25 C 8.996094 25 7 23.003906 7 20.53125 L 7 11.46875 C 7 8.996094 8.996094 7 11.46875 7 Z M 21.90625 9.1875 C 21.402344 9.1875 21 9.589844 21 10.09375 C 21 10.597656 21.402344 11 21.90625 11 C 22.410156 11 22.8125 10.597656 22.8125 10.09375 C 22.8125 9.589844 22.410156 9.1875 21.90625 9.1875 Z M 16 10 C 12.699219 10 10 12.699219 10 16 C 10 19.300781 12.699219 22 16 22 C 19.300781 22 22 19.300781 22 16 C 22 12.699219 19.300781 10 16 10 Z M 16 12 C 18.222656 12 20 13.777344 20 16 C 20 18.222656 18.222656 20 16 20 C 13.777344 20 12 18.222656 12 16 C 12 13.777344 13.777344 12 16 12 Z"></path>
                </svg>
               </a>

            </div>

          </div>
      </div>
      <hr className='mt-24 border-zinc-900 border-0.5'/>
      <p className='text-white text-center font-thin text-xs text-zinc-500 pt-2 pb-1'>
        &copy; 2023 Elevty Inc. Todos los derechos reservados.
      </p>
      </footer>
    )
}

const mapStateToProps=state=>({

})

export default connect(mapStateToProps, {

}) (Footer)
