//IMPORTS
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import store from './store';
import { Helmet, HelmetProvider } from 'react-helmet-async'; //To SEO
import { Provider } from 'react-redux';
import AnimatedRoutes from 'Routes';


//cg
// MAIN APP
function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Elevty Agency | Marketing Digital & Ventas</title>
        <meta name="description" content="Agenda el doble de llamadas con Elevty, la agencia que ayuda a empresas IT a aumentar el número de leads calificados con una estructura sólida y predecible de adquisición de clientes" />
        <meta name="keywords" content='agencia, growth partner, empresas it, agencia growth partner para it, agendar llamadas, aumentar ventas, optimizacion seo, marketing digital, marketing digital para empresas IT, seguimiento KPI, seguimiento KPI para empresas IT, marketing digital para empresas en Chile, marketing digital para empresas en Colombia, marketing digital para empresas en Perú, aumento de leads, aumento de leads calificados, optimización en linkedin, optimización de perfiles en linkedin, automatización de ventas, elevty, elevty.com, elevty agencia, elevty agencia de marketing digital, elevty agencia de growth partner, elevty agencia de Chile, elevty agendar llamadas, marketing para empresas, marketing digital para empresas, marketing digital para empresas IT, adquisicion de clientes, adquisicion de clientes para empresas IT, adquisicion de clientes para empresas IT por linkedin' />
        <meta name="robots" content='all' />
        <link rel="canonical" href="http://www.elevty.com/" />
        <meta name="author" content='Elevty' />
        <meta name="publisher" content='Elevty' />

        {/* Social Media Tags */}
        <meta property="og:title" content='Elevty | Agenda el doble de llamadas con el mejor servicio de Marketing Digital de Latinoamerica' />
        <meta property="og:description" content='Agenda el doble de llamadas con Elevty, la agencia que ayuda a empresas IT a aumentar el número de leads calificados en tan solo un mes' />
        <meta property="og:url" content="http://www.elevty.com/" />
        <meta property="og:image" content='%PUBLIC_URL%/logo-elevty.png' />

        <meta name="twitter:title" content='Elevty | Agenda el doble de llamadas con el mejor servicio de Marketing Digital de Latinoamerica' />
        <meta
            name="twitter:description"
            content='Lograrás conseguir el doble de ventas en un mes, agenda una reunion con nosotros y te garantizaremos resultados por contrato'
        />
        <meta name="twitter:image" content='%PUBLIC_URL%/logo-elevty.png' />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Provider store={store}>
        <Router>
            <AnimatedRoutes/>
        </Router>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
