import Bg from './../../assets/img/fondo.avif'
import LogoCanvas from './LogoCanvas'

function AboutUs(){
  return(
    <div className='flex py-[200px] relative items-center justify-center bg-cover text-center w-full' style={{ backgroundImage: `url(${Bg})` }}>
      <div className='flex flex-col items-center justify-center backdrop-blur-lg px-[40px] sm:px-[100px] md:px-[200px] w-full h-full absolute bg-gradient-to-r from-neutroColor from-10% via-black/70 to-neutroColor to-90% bg-gradient-to-t from-neutroColor from-10% via-black/70% to-neutroColor to-90%'>
        <p className="text-2xl md:text-4xl sm:text-3xl text-zinc-500/80 font-montserrat font-semibold tracking-wider">Descubre todas las ventajas de LinkedIn como herramienta de adquisición</p>
        <LogoCanvas />
      </div>
    </div>
  )
}

export default AboutUs
