import React, { useState } from "react";

const Accordion = ({ title, answer }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  return (
    <div className="py-10">
      <button
        onClick={() => setAccordionOpen(!accordionOpen)}
        className="flex ml-10 sm:ml-20 lg:ml-36 items-center"
      >
        <span className='font-bold text-stone-200 font-exo text-4xl'>{title}</span>       {/* {accordionOpen ? <span>-</span> : <span>+</span>} */}
        <svg
          className="fill-baseColor shrink-0 ml-[36px]"
          width="16"
          height="16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            y="7"
            width="16"
            height="2"
            rx="1"
            className={`transform origin-center transition duration-200 ease-out ${
              accordionOpen && "!rotate-180"
            }`}
          />
          <rect
            y="7"
            width="16"
            height="2"
            rx="1"
            className={`transform origin-center rotate-90 transition duration-200 ease-out ${
              accordionOpen && "!rotate-180"
            }`}
          />
        </svg>


      </button>
      <div
        className={`grid overflow-hidden transition-all duration-300 ease-in-out text-slate-600 text-sm ${
          accordionOpen
            ? "grid-rows-[1fr] opacity-100"
            : "grid-rows-[0fr] opacity-0"
        }`}
      >
        <div className='items-center flex flex-col sm:flex-row justify-between overflow-hidden'>
          <div className="flex items-center justify-center sm:pl-20 lg:pl-36 text-justify px-10 sm:w-1/2 font-alegreya text-lg text-neutral-600 pt-4">{answer}</div>

          <svg className='mr-0 sm:mr-20 lg:mr-60 pt-10 sm:pt-0' fill="#2C2C2C" height="200px" width="200px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" 
	 viewBox="0 0 358.292 358.292" space="preserve">
<g>
	<path d="M333.982,268.223c-2.671,0-5.357,0.735-7.985,2.186c-20.909,11.541-47.708,20.677-71.688,24.438
		c-3.494,0.548-7.565,0.826-12.098,0.826c-11.703,0.001-25.235-1.829-36.198-4.895c-9.952-2.783-15.314-5.977-16.815-8.36
		c2.276,0.129,6.337,0.891,10.973,1.76c9.39,1.76,22.249,4.17,34.231,4.17c5.817,0,10.947-0.582,15.248-1.73
		c11.086-2.958,14.919-10.201,14.81-15.993c-0.109-5.771-3.9-10.643-9.219-11.85c-2.493-0.565-8.467-2.486-16.03-4.918
		c-21.475-6.904-57.416-18.46-75.654-18.998c-11.466-0.349-39.013,3.819-54,6.261c-0.488,0.079-0.956,0.149-1.404,0.216
		c-3.87,0.579-7.872,1.178-8.243,6.837c-0.622,9.544-1.521,23.637-2.455,38.264c-1.113,17.444-2.275,35.649-3.074,47.805
		c-0.029,0.457,0.145,3.127,1.699,4.788c0.805,0.859,1.837,1.314,2.985,1.314c1.409,0,2.889-0.677,4.521-2.069
		c7.741-6.6,14.296-9.363,19.463-8.192c10.441,2.369,97.004,18.343,122.14,18.345c0.003,0,0.004,0,0.006,0
		c2.371,0,4.131-0.141,5.381-0.432c20.003-4.649,76.469-37.784,88.119-48.978c7.482-7.189,10.275-15.633,7.475-22.589
		C344.131,271.367,339.462,268.223,333.982,268.223z"/>
	<path d="M74.158,240.279l-52.915-2.607c-5.085-0.278-10.096,3.512-11.338,8.361l-0.035,0.139l-9.623,87.365
		c-0.581,2.436-0.126,4.886,1.257,6.741c1.411,1.892,3.69,3.034,6.254,3.133l0.078,0.002l58.566-0.007
		c0.122,0.004,0.245,0.007,0.366,0.007c5.068,0,9.344-3.956,9.733-9.007l6.439-84.166c0.193-2.523-0.617-4.952-2.281-6.839
		C78.995,241.512,76.685,240.404,74.158,240.279z M55.511,315.175c0,6.949-5.655,12.603-12.605,12.603
		c-6.948,0-12.601-5.654-12.601-12.603c0-6.949,5.653-12.604,12.601-12.604C49.856,302.572,55.511,308.226,55.511,315.175z"/>
	<path d="M356.418,12.189c-2.13-2.46-5.845-3.027-9.763-1.408l-64.158,26.468c-3.433,1.416-5.537,3.767-5.926,6.618
		c-0.388,2.853,1.012,5.681,3.941,7.963l15.292,11.914l-56.176,84.51l-28.362-30.767c-4.007-4.349-9.574-6.565-15.286-6.059
		c-5.707,0.498-10.813,3.64-14.007,8.619l-42.453,66.2l-17.258-14.605c-7.462-6.31-18.894-6.531-26.592-0.518l-32.442,25.351
		c-6.243,4.879-7.351,13.896-2.472,20.14c2.83,3.621,7.051,5.514,11.315,5.514c3.089,0,6.201-0.993,8.824-3.042l27.56-21.536
		l20.855,17.648c4.253,3.597,9.802,5.192,15.218,4.377c5.42-0.814,10.252-3.97,13.259-8.657l41.264-64.346l28.15,30.537
		c3.976,4.316,9.512,6.534,15.192,6.084c5.678-0.449,10.798-3.511,14.047-8.398l62.063-93.367l16.021,12.482
		c1.987,1.548,4.095,2.366,6.095,2.366c0.001,0,0,0,0.001,0c3.958,0,6.854-3.011,7.557-7.858l9.967-68.688
		C358.596,16.625,357.999,14.016,356.418,12.189z"/>
</g>
</svg>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
