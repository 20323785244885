import React, { useEffect, useRef } from 'react';

const HowTo = () => {

 const canvasRef1 = useRef(null);
 const canvasRef2 = useRef(null);
 const canvasRef3 = useRef(null);


  useEffect(() => {
    const canvas1 = canvasRef1.current;
    const ctx1 = canvas1.getContext('2d');

    // Limpiar el canvas
    ctx1.clearRect(0, 0, canvas1.width, canvas1.height);

    // Configurar la línea
    ctx1.strokeStyle = '#a8eb12'; // Color de la línea
    ctx1.lineWidth = 2; // Grosor de la línea

    // Dibujar semicirculo 
    ctx1.beginPath();
    ctx1.moveTo(390, 10); // Mover el punto de inicio después del semicírculo
    ctx1.lineTo(60,10); // Dibujar la línea
    ctx1.arcTo(40, 10, 40, 15, 25)
    ctx1.lineTo(40, 110)
    ctx1.stroke();

    //dibujar triangulo
    ctx1.beginPath();
    ctx1.fillStyle = '#a8eb12'
    ctx1.moveTo(35, 110)
    ctx1.lineTo(45, 110)
    ctx1.lineTo(40, 120)
    ctx1.lineTo(35, 110)
    ctx1.fill();
  }, []);




  useEffect(() => {
    const canvas2 = canvasRef2.current;
    const ctx2 = canvas2.getContext('2d');

    // Limpiar el canvas
    ctx2.clearRect(0, 0, canvas2.width, canvas2.height);

    // Configurar la línea
    ctx2.strokeStyle = '#a8eb12'; // Color de la línea
    ctx2.lineWidth = 2; // Grosor de la línea

    // Dibujar la línea curva en forma de gancho
    ctx2.beginPath();
    ctx2.moveTo(10, 115); // Mover el punto de inicio después del semicírculo
    ctx2.lineTo(340,115); // Dibujar la línea
    ctx2.arcTo(360, 115, 360, 105, 25)
    ctx2.lineTo(360, 10);
    ctx2.stroke();

    // Dibujar Triangulo
    ctx2.beginPath();
    ctx2.fillStyle = '#a8eb12'
    ctx2.moveTo(10, 110);
    ctx2.lineTo(10, 120);
    ctx2.lineTo(0, 115);
    ctx2.lineTo(10, 110);
    ctx2.fill();
  }, []);
    
  useEffect(() => {
    const canvas3 = canvasRef3.current;
    const ctx3 = canvas3.getContext('2d');

    // Limpiar el canvas
    ctx3.clearRect(0, 0, canvas3.width, canvas3.height);

    // Configurar la línea
    ctx3.strokeStyle = '#a8eb12'; // Color de la línea
    ctx3.lineWidth = 2; // Grosor de la línea

    // Dibujar la línea curva en forma de gancho
    ctx3.beginPath();
    ctx3.moveTo(10, 70); // Mover el punto de inicio después del semicírculo
    ctx3.lineTo(290,70); // Dibujar la línea
    ctx3.stroke();

    // Dibujar trinagulo 
    ctx3.beginPath();
    ctx3.fillStyle = '#a8eb12'
    ctx3.moveTo(290, 65);
    ctx3.lineTo(290, 75);
    ctx3.lineTo(300, 70);
    ctx3.lineTo(290, 65);
    ctx3.fill();
  }, []);

  return (
    <>
      <div id='metodo' className='bg-neutroColor text-center pt-28'>
        <p className='text-slate-200 font-semibold text-4xl font-exo'>¿Cómo lo hacemos?</p>
        <p className='font-overpass text-slate-200 font-light text-md pb-4'>(de forma simple)</p>
        <hr className='border-zinc-900 border-2'/>
      </div>
      <div className='bg-neutral-900 flex flex-col items-center pt-24'>
        <div className='translate-x-4 flex'>
          <canvas
            ref={canvasRef1}
            width={400}
            height={140}
            className='hidden sm:flex py-4 -translate-y-2'
          />
          <p className='px-4 text-3xl font-exo font-semibold text-slate-200'>Optimización</p>
        </div>
        <div className='flex -translate-x-7'>
          <p className='px-4 text-3xl font-exo font-semibold text-slate-200'>Calificación</p>
          <canvas
            ref={canvasRef3}
            width={300}
            heihgt={120}
            className='-translate-y-16 py-4 px-4 hidden sm:flex'
          />
          <p className='px-4 text-3xl font-exo font-semibold text-slate-200'>Filtro</p>
        </div>
        <div className='flex -translate-x-7'>
          <p className='px-4 text-3xl font-exo font-semibold text-slate-200'>Prospección</p>
          <canvas
            ref={canvasRef2}
            width={400}
            heihgt={140}
            className='py-4 -translate-y-28 px-4 hidden sm:flex'
          />
        </div>
      </div>
    </>
  );
};

export default HowTo;
