import AccordionMision from './AccordionMision'
import AccordionVision from './AccordionVision'


function Guarantee(){
  return(
    <div>
      <hr className='border-zinc-900 border-2'/>
      {/* Guarantee Container */}
      <div id='nosotros' className='bg-gradient-to-b from-neutral-950 to-neutroColor to-90% flex flex-col py-24 sm:py-36'>
        {/* Title */}
        <div className='text-4xl sm:text-5xl text-center font-neueMontreal tracking-wider font-bold text-white'>
          ¿Qué es <span className='text-baseColor'>Elevty</span>?
          <p className='py-6 text-sm sm:text-base text-center px-10 sm:px-20 font-montserrat tracking-wide text-stone-600'>
            Somos una agencia de gestión de LinkedIn que expande las nuevas herramientas digitales a las empresas Latinoamericanas con un sistema de adquisición de clientes especifico para tu negocio<span className='text-baseColor'> sin que tengas que invertir en anuncios pagos</span>
          </p>
        </div>
        {/* Mision */}
        <div className='pt-10'>
          <AccordionMision
            title='Misión'
            answer='Aumentar la productividad de las empresas Latinoamericanas con el uso de las nuevas tecnologías en el mercado y apoyar el crecimiento de los negocios que los proveen.'
          />
          <AccordionVision
            title='Visión'
            answer='Elevar al máximo el uso de las nuevas herramientas digitales en las empresas Latinoamericanas fomentando siempre el crecimiento del sector tecnológico.'
          />
        </div>
        {/* Vision */}
        <div>
        </div>
      </div>
    </div>
  )
}

export default Guarantee
