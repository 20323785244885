import HeaderBg from './../../assets/img/fondo_elevty_2.avif';
import { Link } from 'react-scroll'



function Header(){
  return(
    <div className='py-[300px] relative bg-cover bg-center' style={{ backgroundImage: `url(${HeaderBg})` }}>
      <div id='header' className='w-full flex top-0 items-center backdrop-blur-lg h-full absolute bg-gradient-to-r from-black from-45% via-black/70 to-black to-95%'>
      {/* Text container */}
        <div className='items-center px-10 w-full z-50 sm:px-24'>
          <h1 className='tracking-wider font-neueMontreal text-2xl xs:text-4xl sm:text-5xl text-white w-full text-center lg:text-start lg:w-[45%] pt-[10px] pb-[20px]'>Controla al <span className='text-baseColor'>100% </span>tu número de clientes</h1>
          <p className='font-montserrat text-justify text-stone-400 text-[11px] sm:text-sm font-montserrat w-full lg:w-2/5'>Afianza leads calificados por medio de nuestro sistema de adquisición de clientes predecible. <span className='font-none font-bold text-white'>No te preocupes nunca mas en adquirir clientes para tu negocio</span></p>

       {/* CTA */}
          <div className='flex flex-col items-center justify-between md:justify-center lg:flex-row lg:w-2/5 lg:justify-between w-full' >
          <a href="https://calendly.com/axelreyes/llamada-de-auditoria-elevty" target="_blank" rel="noopener noreferrer" className=''>
            <button className="bg-baseColor rounded-sm p-[16px] px-[10px] md:p-[16px] text-[11px] sm:text-sm hover:bg-lime-500 transition duration-200 font-neueMontreal translate-y-5">Agendar Reunión Gratuita</button>
          </a>
          <Link
            to='nosotros'
            spy={true}
            smooth={true}
            offset={-150}
            duration={800}
            className='border border-baseColor rounded-sm cursor-pointer translate-y-5 py-[10px] px-[32px] md:px-6 md:py-0 mt-4 lg:mt-0 hover:bg-neutral-800/10 transition duration-300' 
          >
            <p className='p-[6px] md:p-[16px] font-neueMontreal text-center text-[11px] sm:text-sm text-baseColor'>Mas Información</p>
          </Link>
        </div>
        </div>
        {/* Gradiente de arriba hacia abajo */}
        <div className='w-full h-full absolute bg-gradient-to-b from-black from-10% to-black to-95% via-black/0' />
      </div>
    </div>
  )

}
export default Header
