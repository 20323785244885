//IMPORTS
import { useEffect } from "react"

//Layout
import Footer from "components/navigation/Footer"
import Navbar from "components/navigation/Navbar"
import Layout from "hocs/Layout"

//Components
import Header from "components/home/Header.js"
import Guarantee from "components/home/Guarantee.js"
import AboutUs from "components/home/AboutUs.js"
import Services from "components/home/Services.js"
import HowTo from "components/home/HowTo.js"
import Cta from "components/home/Cta.js"
function Home(){
    useEffect(()=>{
        window.scrollTo(0,0)
    },[])
    return(
        <Layout>
            <Navbar/>
            <div data-scroll-section>
                <Header/>
                <Guarantee/>
                <AboutUs />
                <Services />
                <HowTo/>
                <Cta />
            </div>
                <Footer/>
        </Layout>
    )
}
export default Home
