import { useEffect, useState } from 'react';


function Services() {
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);


  const handleScroll = () => {
    const scrolled = window.scrollY;
    // Ajusta el valor 200 según la posición en la que quieras que aparezcan los elementos
    setIsVisible(scrolled > 1100);
    setIsVisible2(scrolled > 1400);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div id='servicios' className='bg-neutroColor p-36 flex flex-col'>
      {/* M & C */}
      <div className={`w-1/2 transition-opacity duration-[2s] p-8 ${isVisible ? 'opacity-100' : 'opacity-0'}`}> 
        <p className='pb-4 text-center font-exo text-3xl font-bold text-stone-200'>Multiplica & Controla</p>
        <ul className='flex flex-col'>
          <li className='py-2'>
            <p className='text-overpass text-xl font-semibold text-stone-200'>Sistema de adquisición predecible</p>
            <p className='text-overpass text-md font-thin text-stone-300'>Despreocupate de la obtención de clientes, con nuestro método, el crecimiento es inevitable y los clientes nunca faltarán.</p>
          </li>
          <li className='py-2'>
            <p className='text-overpass text-xl font-semibold text-stone-200'>Leads calificados</p>
            <p className='text-overpass text-md font-thin text-stone-300'>Los clientes obtenidos serán altamente calificados para tu negocio en específico gracias a un sistema de doble filtro.</p>
          </li>
          <li className='py-2'>
            <p className='text-overpass text-xl font-semibold text-stone-200'>Garantía</p>
            <p className='text-overpass text-md font-thin text-stone-300'>Los resultados son garantizados mediante contrato la tasa de crecimiento es siempre positva.</p>
          </li>
        </ul>
      </div>
      {/* O & C */}
      <div className={`w-1/2 transition-opacity duration-[2s] ml-auto p-8 ${isVisible2 ? 'opacity-100' : 'opacity-0 '}`}>
        <p className='pb-4 text-center font-exo text-3xl font-bold text-stone-200'>Optimiza & Crea</p>
        <ul className='flex flex-col'>
          <li className='py-2'>
            <p className='text-overpass text-xl font-semibold text-stone-200'>Marca empresarial</p>
            <p className='text-overpass text-md font-thin text-stone-300'>Darle a tu negocio una marca empresarial, profesional  y seria, genera en tus clientes respeto y confianza. Haz esto y posicionate dentro de los mejores en tu sector!</p>
          </li>
          <li className='py-2'>
            <p className='text-overpass text-xl font-semibold text-stone-200'>Gestión automática</p>
            <p className='text-overpass text-md font-thin text-stone-300'>Nosotros como agencia nos encargaremos de adquirir leads calificados por ti, enfocate en dar el mejor servicio y optimiza tus tiempos.</p>
          </li>
          <li className='py-2'>
            <p className='text-overpass text-xl font-semibold text-stone-200'>Valor</p>
            <p className='text-overpass text-md font-thin text-stone-300'>Tu negocio aportará el mayor valor posible con el fin de crear una buena imagen empresarial que beneficiará en todos los sentidos a la marca.</p>
          </li>
        </ul>
      </div>
     <div className='pt-10 justify-center flex'>
        <a href="https://calendly.com/axelreyes/llamada-de-auditoria-elevty" target="_blank" rel="noopener noreferrer" className=''>
            <button className="bg-baseColor rounded-lg font-semibold px-[12px] py-[12px] text-sm hover:bg-lime-500 transition duration-200 font-overpass">Agendar Reunión Gratuita</button>
       </a>
      </div>

    </div>

  )

}

export default Services
