//IMPORTS
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'

//Pages from 'containers/pages' 
//ex: import Contact from 'containers/pages/Contact';
import Error404 from 'containers/errors/Error404'; // errors directory
import Home from 'containers/pages/Home';



//framer motion
import { AnimatePresence } from 'framer-motion'


//MAIN COMP
function AnimatedRoutes(){

    const location = useLocation()

    return(
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                    {/* Error Display */}
                    <Route path="*" element={<Error404 />} />

                    {/* Home Display */}
                    <Route path="/" element={<Home />} />
                </Routes>
        </AnimatePresence>
    )
}
export default AnimatedRoutes
