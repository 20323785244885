import { Link } from 'react-scroll'


function Cta(){
  return(
    <div className='flex flex-col'>
      <p className='bg-neutroColor font-overpass font-thin text-slate-300 text-md text-center pt-5 pb-1'>Puedes agendar una reunion y ver el funcionamiento adecuado del método para tu negocio</p>
      {/* b arrow */}
      <div className='bg-neutroColor flex justify-center'>
        <Link
          to="cta"  // Reemplaza 'seccionDestino' con el ID de la sección a la que quieres ir
          spy={true}
          smooth={true}
          offset={-150}  // Ajusta el offset según el diseño de tu página
          duration={500}
          className="bottom-8 cursor-pointer mb-4"
        >
          <svg className='fill-slate-200 hover:fill-stone-400 transition duration-300' width="24" height="19" xmlns="http://www.w3.org/2000/svg" clip="evenodd"><path d="M23.245 4l-11.245 14.374-11.219-14.374-.781.619 12 15.381 12-15.391-.755-.609z"/></svg>
        </Link>
      </div>
      <div id='cta' className='bg-baseColor py-24 flex flex-col items-center'>
        <p className='text-5xl font-exo text-center font-semibold px-10 pb-24'>Comienza hoy y deja a un lado el gasto de tiempo, <span className='text-5xl text-white'>Adquiere el doble de clientes de forma automática</span></p>
        <iframe className='w-[540px] h-[500px] block ml-auto mr-auto max-w-full' src="https://488c1674.sibforms.com/serve/MUIFAGKmWQsxh6XTaPpYF6JeCiLXuQcd0rQGAIAKMQ5bkdOvz45LiI26K7z3Y37wMpjMDBTlBjeOGt172l0lN67QG3xvvI__V42Zz_jQ4Jt1HzJfsoFmejxTcyihFcu9mPFbyIU1pVomDDv74GG6Wpzj_sPMP4CqTIPmc5PSoaj3j74HYkyPrT84aMRG5ctTnMmRj8DJ9O4gbztJ" frameborder="0" scrolling="auto" allowfullscreen></iframe>
      </div>
    </div>

  )
}


export default Cta
