import {connect} from 'react-redux'
import {useState} from 'react'
import {Link as RouterLink} from 'react-router-dom'
import {Link as ScrollLink} from 'react-scroll'
import logoElevty from './../../assets/img/icon_elevty.png'

function Navbar(){
    {/* Smooth Navbar scroll */}
    const [loading,setLoading]=useState(true)

    window.onscroll = function() {scrollFunction()}

    function scrollFunction() {
        if(document.getElementById('navbar')){
            if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
                document.getElementById('navbar').classList.add('shadow-navbar');
                document.getElementById('navbar').classList.add('bg-navColor');
            }else{
                document.getElementById('navbar').classList.remove('shadow-navbar');
                document.getElementById('navbar').classList.remove('bg-navColor');
            }
        }
    }

    const [open, setOpen] = useState(false)
    return(
        <nav data-scroll data-scroll-id="hey" id="navbar" className='w-full fixed transition duration-300 ease-in-out z-40'>
            <div className='flex items-center justify-between px-14 py-2'>
                <RouterLink to='/'>
                    <img src={logoElevty} className='h-[65px]'/>
                </RouterLink>
                <div className='flex justify-between items-center w-[62%] lg:w-1/2'>
                    {/* Nosotros */}
                    <ScrollLink
                        to='nosotros'
                        spy={true}
                        smooth={true}
                        offset={-10}
                        duration={800}
                        className='hidden md:block cursor-pointer' 
          >
                        <p className='font-montserrat text-neutral-200'>Nosotros</p>
                    </ScrollLink>
                    {/* Servicios */}
                    <ScrollLink
                        to='servicios'
                        spy={true}
                        smooth={true}
                        offset={-40}
                        duration={800}
                        className='hidden md:block cursor-pointer' 
          >
                        <p className='font-montserrat text-neutral-200'>Servicios</p>
                    </ScrollLink>
                   {/* Método */}
                    <ScrollLink
                        to='metodo'
                        spy={true}
                        smooth={true}
                        offset={10}
                        duration={800}
                        className='hidden md:block cursor-pointer' 
          >
                        <p className='font-montserrat text-neutral-200'>Método</p>
                    </ScrollLink>
                  {/* CTA */}
                    <a href="https://calendly.com/axelreyes/llamada-de-auditoria-elevty" target="_blank" rel="noopener noreferrer">
                        <button className="bg-baseColor rounded-lg font-semibold px-[12px] py-[12px] text-sm right-20 hidden md:block hover:bg-lime-500 transition duration-200 font-overpass">¡Hagamoslo!</button>
                    </a>

                </div>
            </div>
         </nav>
    )
}

const mapStateToProps=state=>({

})

export default connect(mapStateToProps, {

}) (Navbar)

