import React, { useState } from "react";

const Accordion = ({ title, answer }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  return (
    <div className="py-10">
      <button
        onClick={() => setAccordionOpen(!accordionOpen)}
        className="flex ml-10 sm:ml-20 lg:ml-36 items-center"
      >
        <span className='font-bold text-stone-200 font-exo text-4xl'>{title}</span>
        {/* {accordionOpen ? <span>-</span> : <span>+</span>} */}
        <svg
          className="fill-baseColor shrink-0 ml-8"
          width="16"
          height="16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            y="7"
            width="16"
            height="2"
            rx="1"
            className={`transform origin-center transition duration-200 ease-out ${
              accordionOpen && "!rotate-180"
            }`}
          />
          <rect
            y="7"
            width="16"
            height="2"
            rx="1"
            className={`transform origin-center rotate-90 transition duration-200 ease-out ${
              accordionOpen && "!rotate-180"
            }`}
          />
        </svg>
      </button>
      <div
        className={`grid overflow-hidden transition-all duration-300 ease-in-out text-slate-600 text-sm ${
          accordionOpen
            ? "grid-rows-[1fr] opacity-100"
            : "grid-rows-[0fr] opacity-0"
        }`}
      >
        <div className='items-center flex flex-col sm:flex-row justify-between overflow-hidden'>
          <div className="flex items-center justify-center sm:pl-20 lg:pl-36 text-justify px-10 sm:w-1/2 font-alegreya text-lg text-neutral-600 pt-4">{answer}</div>
          <svg className='mr-0 sm:mr-20 lg:mr-60 pt-10 sm:pt-0' fill="#2C2C2C" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" 
	 width="200px" height="200px" viewBox="0 0 41.75 41.75" space="preserve"
	>
<g>
	<path d="M19.3,31.117c-0.308-0.309-0.678-0.508-1.066-0.621c1.604-2.939,1.166-6.705-1.318-9.189
		c-0.418-0.418-0.875-0.771-1.355-1.074V19.5c0-2.209-1.791-4-4-4s-4,1.791-4,4v0.585c-0.572,0.331-1.115,0.731-1.605,1.222
		c-3.021,3.021-3.021,7.938,0,10.961c2.485,2.484,6.252,2.922,9.191,1.318c0.113,0.391,0.312,0.76,0.62,1.066l6.364,6.365
		c0.978,0.977,2.56,0.977,3.535,0c0.976-0.978,0.978-2.56,0-3.537L19.3,31.117z M14.792,30.146c-1.852,1.853-4.865,1.853-6.717,0
		c-1.853-1.854-1.853-4.865,0-6.719c1.852-1.853,4.865-1.853,6.717,0C16.645,25.279,16.645,28.293,14.792,30.146z M38.06,4v25.5
		c0,2.209-1.791,4-4,4s-4-1.791-4-4V4c0-2.209,1.791-4,4-4S38.06,1.791,38.06,4z M28.31,14v15.5c0,2.209-1.791,4-4,4
		c-2.208,0-4-1.791-4-4V14c0-2.209,1.792-4,4-4C26.519,10,28.31,11.791,28.31,14z"/>
</g>
</svg>



          
        </div>
      </div>
    </div>
  );
};

export default Accordion;
